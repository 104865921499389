const config = {
  defaultPage: '/workspace/list',
  workspace: {
    paymentInfoEnable: false,
    downloadCSVEnable: true,
    editWorkspaceEnable: true,
    addWorksapceEnable: true,
    addMemberEnable: false,
    maxMemberEditable: false,
    expiredAtEditable: false,
    statusEditable: false,
  },
}

export default config

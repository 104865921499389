import GitInfo from 'react-git-info/macro'

const gitInfo = GitInfo()

let confProp
if (process.env.REACT_APP_TARGET === 'production') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'https://api.rework.so/v1',
      URL: 'https://api.rework.so/__admin/graphql',
      wsURL: 'wss://api.rework.so:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'test') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'https://dev.api.rework.so/v1',
      URL: 'https://dev.api.rework.so/__admin/graphql',
      wsURL: 'wss://dev.api.rework.so:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'enterprise_prod') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'https://api.enterprise.rework.so/v1',
      URL: 'https://api.enterprise.rework.so/__admin/graphql',
      wsURL: 'wss://api.enterprise.rework.so:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'enterprise_dev') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'https://dev.api.enterprise.rework.so/v1',
      URL: 'https://dev.api.enterprise.rework.so/__admin/graphql',
      wsURL: 'wss://dev.api.enterprise.rework.so:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'officemail_prod') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'https://api.officemail.io/v1',
      URL: 'https://api.officemail.io/__admin/graphql',
      wsURL: 'wss://api.officemail.io:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'officemail_dev') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'https://dev-api.officemail.io/v1',
      URL: 'https://dev-api.officemail.io/__admin/graphql',
      wsURL: 'wss://dev-api.officemail.io:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'test1') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'http://chat-test.rework.so:8001/v1',
      URL: 'http://chat-test.rework.so:8001/__admin/graphql',
      wsURL: 'ws://chat-test.rework.so:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'log') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'http://192.168.50.100:8001/v1',
      URL: 'http://192.168.50.100:8001/__admin/graphql',
      wsURL: 'ws://192.168.50.100:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'log2') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'http://192.168.50.102:8001/v1',
      URL: 'http://192.168.50.102:8001/__admin/graphql',
      wsURL: 'ws://192.168.50.102:8002/graphql/',
    },
  }
} else {
  confProp = {
    DEBUG: true,
    SERVER: {
      restURL: 'http://localhost:8001/v1',
      URL: 'http://localhost:8001/__admin/graphql',
      wsURL: 'ws://localhost:8002/graphql/',
    },
  }
}

confProp.defaultLocale = 'en-US' // 'ko-KR'

if (window.globalConfig != null) {
  if (window.globalConfig.restURL != null) {
    confProp.SERVER.restURL = window.globalConfig.restURL
  }
  if (window.globalConfig.URL != null) {
    confProp.SERVER.URL = window.globalConfig.URL
  }
  if (window.globalConfig.LOCALE != null) {
    confProp.defaultLocale = window.globalConfig.LOCALE
  }
}

confProp.gitInfo = gitInfo

const conf = confProp

export default conf

import loadable from '@loadable/component'

export default function getRoutes(config /* , serverRouter */) {
  // return serverRouter
  const routes = [
    {
      path: '/workspace/list',
      Component: loadable(() => import('pages/workspace/list'), config),
    },
    {
      path: '/workspace/detail/:id',
      Component: loadable(() => import('pages/workspace/detail'), config),
    },
    {
      path: '/users/detail/:id',
      Component: loadable(() => import('pages/users/simple-detail'), config),
    },
    // {
    //   path: '/users/detail/:id',
    //   Component: loadable(() => import('pages/users/detail'), config),
    // },
    // {
    //   path: '/signature/list',
    //   Component: loadable(() => import('pages/signature/list'), config),
    // },
    // {
    //   path: '/accountinfo/detail/:id',
    //   Component: loadable(() => import('pages/accountinfo/detail'), config),
    // },
    // {
    //   path: '/signature/detail/:id',
    //   Component: loadable(() => import('pages/signature/detail'), config),
    // },
    // {
    //   path: '/signature/edit/:id',
    //   Component: loadable(() => import('pages/signature/edit'), config),
    // },
    // {
    //   path: '/signature/create/:id',
    //   Component: loadable(() => import('pages/signature/edit'), config),
    // },
  ]

  return routes
}

import { modifyObject } from 'server-config'

// const config = {
//   defaultPage: '/dashboard',
//   workspace: {
//     paymentInfoEnable: true,
//     downloadCSVEnable: true,
//     editWorkspaceEnable: true,
//     addWorksapceEnable: true,
//     addMemberEnable: true,
//     maxMemberEditable: true,
//     expiredAtEditable: true,
//     statusEditable: true,
//   },
// }

export default function configFunc(cConfig) {
  // return serverConfig
  const config = {
    defaultPage: '/workspace/list',
    workspace: {
      paymentInfoEnable: false,
      downloadCSVEnable: false,
      editWorkspaceEnable: false,
      addWorksapceEnable: false,
      addMemberEnable: false,
      maxMemberEditable: true,
      expiredAtEditable: true,
      statusEditable: true,
      addAllowedDomainEnable: false,
      editAllowedDomainEnable: false,
      addAppConfigEnable: false,
      editAppConfigEnable: false,
      unlinkUserEnable: false,
      cancelSubscriptionEnable: false,
      addSignatureEnable: true,
      editSignatureEnable: true,
    },
  }

  return modifyObject(cConfig, config)
}

const config = {
  defaultPage: '/dashboard',
  workspace: {
    paymentInfoEnable: false,
    downloadCSVEnable: false,
    editWorkspaceEnable: false,
    addWorksapceEnable: false,
    addMemberEnable: false,
    maxMemberEditable: false,
    expiredAtEditable: false,
    statusEditable: false,
  },
}

export default config

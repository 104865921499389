import i18n from 'i18n'

export default function getMenuList() {
  const menuList = [
    {
      title: i18n.t('dashboard.title'),
      // title: 'Dashboard',
      key: 'dashboardnine',
      url: '/dashboard',
      icon: 'fe fe-home',
    },
    {
      title: i18n.t('workspace.title.list'),
      // title: 'Workspace List',
      key: 'workspaceList',
      url: '/workspace/list',
      icon: 'fe fe-layers',
    },
    // {
    //   category: true,
    //   title: i18n.t('menu.category.enterpriseMaster'),
    // },
    {
      title: i18n.t('autodiscover.title.list'),
      // title: 'Workspace List',
      key: 'autodiscoverList',
      url: '/autodiscover/list',
      icon: 'fe fe-link',
    },
    // {
    //   title: i18n.t('enterpriseSite.title.list'),
    //   // title: 'Workspace List',
    //   key: 'enterpriseSite',
    //   url: '/enterprise-site/list',
    //   icon: 'fe fe-briefcase',
    // },
    {
      divider: true,
    },
    {
      title: i18n.t('sysInfo.title.detail'),
      // title: 'Workspace List',
      key: 'systemInfo',
      url: '/systemInfo',
      icon: 'fe fe-monitor',
    },
  ]

  return menuList
}

/* eslint-disable global-require */
import { getEnvParam } from 'env'

export function userConfigFunc(userType, serverConfig) {
  const config = {}

  switch (userType) {
    case 'admin': // 중간 관리자 - reseller 등.
      config.menu = require('./user-admin/menu').default
      config.router = require('./user-admin/router').default
      config.config = require('./user-admin/config').default
      break
    case 'owner': // 최상위 관리자.
    default:
      config.menu = (menu) => {
        return menu
      }
      config.router = (routerConfig, router) => {
        return router
      }
      config.config = (conf) => {
        return conf
      }
  }

  const userConfig = {
    menu: () => {
      return config.menu(serverConfig.menu())
    },
    router: (routerConfig) => {
      return config.router(routerConfig, serverConfig.router(routerConfig))
    },
    config: config.config(serverConfig.config),
  }

  return userConfig
}

const configMap = {}

const defaultCCconfig = {
  defaultPage: '/dashboard',
  workspace: {
    paymentInfoEnable: true,
    downloadCSVEnable: true,
    editWorkspaceEnable: true,
    addWorksapceEnable: true,
    addMemberEnable: true,
    maxMemberEditable: true,
    expiredAtEditable: true,
    statusEditable: true,
    addAllowedDomainEnable: true,
    editAllowedDomainEnable: true,
    addAppConfigEnable: true,
    editAppConfigEnable: true,
    unlinkUserEnable: true,
    cancelSubscriptionEnable: true,
    addSignatureEnable: true,
    editSignatureEnable: true,
  },
}

export function modifyObject(origin, obj) {
  const clone = {}
  Object.keys(origin).forEach((key) => {
    if (typeof origin[key] === 'object' && obj[key] != null) {
      clone[key] = modifyObject(origin[key], obj[key])
    } else {
      clone[key] = obj[key] !== undefined ? obj[key] : origin[key]
    }
  })

  return clone
}

export default function serverConfigFunc() {
  const serverType = getEnvParam('serverType') || ''

  if (configMap[serverType] == null) {
    const sConfig = {}

    // serverType.
    // - REWORK-ONLINE (ex: api.rework.so) - master admin.
    // - REWORK-ENTERPRISE-ONLINE (ex: api.rework.so) - enterprise admin.
    // - REWORK-ENTERPRISE-PORTAL (ex: api.appurityconnect.com) - sub master admin.
    // - REWORK-ENTERPRISE (ex: appdev.kolon.com) - admin

    switch (serverType) {
      case 'REWORK-ONLINE':
        sConfig.menu = require('./rework-online/menu').default
        sConfig.router = require('./rework-online/router').default
        sConfig.config = require('./rework-online/config').default
        break
      case 'REWORK-ENTERPRISE-ONLINE':
        sConfig.menu = require('./rework-enterprise-online/menu').default
        sConfig.router = require('./rework-enterprise-online/router').default
        sConfig.config = require('./rework-enterprise-online/config').default
        break
      case 'REWORK-ENTERPRISE-PORTAL':
        sConfig.menu = require('./rework-enterprise-portal/menu').default
        sConfig.router = require('./rework-enterprise-portal/router').default
        sConfig.config = require('./rework-enterprise-portal/config').default
        break
      case 'REWORK-ENTERPRISE':
        sConfig.menu = require('./rework-enterprise/menu').default
        sConfig.router = require('./rework-enterprise/router').default
        sConfig.config = require('./rework-enterprise/config').default
        break
      case 'OFFICEMAIL-ENTERPRISE-ONLINE':
        sConfig.menu = require('./officemail-enterprise-online/menu').default
        sConfig.router = require('./officemail-enterprise-online/router').default
        sConfig.config = require('./officemail-enterprise-online/config').default
        break
      default:
        sConfig.menu = () => []
        sConfig.router = () => []
        sConfig.config = {
          defaultPage: '/workspace/list',
        }
    }

    const cConfig = modifyObject(defaultCCconfig, sConfig.config)
    sConfig.config = cConfig

    configMap[serverType] = sConfig
  }

  return configMap[serverType]
}

import { ApolloClient, ApolloLink, split, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import store from 'store'
import queryString from 'query-string'

// eslint-disable-next-line import/no-cycle
import { customFetchWithToken } from './services/jwt'

import conf from './conf'

const httpLink = new HttpLink({
  uri: conf.SERVER.URL,
  fetch: customFetchWithToken,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.get('app.user.token')
  // return the headers to the context so httpLink can read them

  const newHeaders = {
    ...headers,
  }

  if (token != null) {
    newHeaders.authorization = `JWT ${token}`
  }

  return {
    headers: newHeaders,
  }
})

let link = null

if (process.env.REACT_APP_WEBSOCKET !== 'true') {
  link = authLink.concat(ApolloLink.from([httpLink]))
} else {
  const wsLink = new WebSocketLink(
    new SubscriptionClient(conf.SERVER.wsURL, {
      reconnect: true,
    }),
  )

  link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    authLink.concat(ApolloLink.from([httpLink])),
  )
}

const cache = new InMemoryCache({
  typePolicies: {
    UserWorkspaceNodeConnection: {
      merge: false,
    },
    WorkspaceAppconfigNodeConnection: {
      merge: false,
    },
  },
})
const client = new ApolloClient({
  link,
  cache,
  queryDeduplication: false,
})

export default client

const httpLinkForRefreshToken = new HttpLink({
  uri: conf.SERVER.URL,
})

const authLinkForRefreshToken = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.get('app.user.refreshToken')
  // return the headers to the context so httpLink can read them

  const newHeaders = {
    ...headers,
  }

  if (token != null) {
    newHeaders.authorization = `JWT ${token}`
  }

  return {
    headers: newHeaders,
  }
})

const linkForRefreshToken = authLinkForRefreshToken.concat(
  ApolloLink.from([httpLinkForRefreshToken]),
)

const cacheForRefreshToken = new InMemoryCache()

export const clientForRefreshToken = new ApolloClient({
  link: linkForRefreshToken,
  cache: cacheForRefreshToken,
  queryDeduplication: false,
})

export const restClient = {
  get: (url, params) => {
    let api = conf.SERVER.restURL + url

    if (params != null) {
      const search = queryString.stringify(params)
      if (search != null) {
        api += `?${search}`
      }
    }
    const ret = fetch(api)

    const promise = new Promise((resolve, reject) => {
      ret
        .then(async (response) => {
          const data = await response.json()
          console.log(' data : ', data)
          const code = response.status
          if (code >= 200 && code < 300) {
            resolve({
              origin: response,
              data,
            })
          } else {
            reject(new Error(data.message || data.detail))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
  post: (url, params) => {
    const api = conf.SERVER.restURL + url

    const ret = fetch(api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'text/plain',
      },
      body: JSON.stringify(params),
      // body: queryString.stringify(params)
    })

    const promise = new Promise((resolve, reject) => {
      ret
        .then(async (response) => {
          const data = await response.json()
          console.log(' data : ', data)
          const code = response.status
          if (code >= 200 && code < 300) {
            resolve({
              origin: response,
              data,
            })
          } else {
            reject(new Error(data.message || data.detail))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
}

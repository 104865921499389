import i18n from 'i18n'

export default function getMenuList(/* serverMenu */) {
  return [
    {
      title: i18n.t('workspace.title.list'),
      // title: 'Workspace List',
      key: 'workspaceList',
      url: '/workspace/list',
      icon: 'fe fe-layers',
    },
  ]
}

/* eslint-disable global-require */
import conf from 'conf'
// import i18n from 'i18n'

import { getServerConfig } from 'env'

export default async function getMenuData() {
  const serverConfig = getServerConfig()
  const menuList = serverConfig.menu()

  if (conf.DEBUG === true) {
    menuList.push({
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-settings utils__spin-delayed--pseudo-selector',
    })
  }

  return menuList
}

import loadable from '@loadable/component'

export default function getRoutes(config) {
  const routes = [
    {
      path: '/dashboard',
      Component: loadable(() => import('pages/dashboard/online'), config),
      exact: true,
    },
    {
      path: '/users/list',
      Component: loadable(() => import('pages/users/list'), config),
    },
    {
      path: '/users/edit/:id',
      Component: loadable(() => import('pages/users/edit'), config),
    },
    {
      path: '/users/detail/:id',
      Component: loadable(() => import('pages/users/detail'), config),
    },
    {
      path: '/users/create',
      Component: loadable(() => import('pages/users/edit'), config),
    },
    {
      path: '/workspace/list',
      Component: loadable(() => import('pages/workspace/list'), config),
    },
    {
      path: '/workspace/detail/:id',
      Component: loadable(() => import('pages/workspace/detail'), config),
    },
    {
      path: '/workspace/edit/:id',
      Component: loadable(() => import('pages/workspace/edit'), config),
    },
    {
      path: '/workspace/create',
      Component: loadable(() => import('pages/workspace/edit'), config),
    },
    {
      path: '/device/list',
      Component: loadable(() => import('pages/device/list'), config),
    },
    {
      path: '/device/detail/:id',
      Component: loadable(() => import('pages/device/detail'), config),
    },
    {
      path: '/device/edit/:id',
      Component: loadable(() => import('pages/device/edit'), config),
    },
    {
      path: '/device/create',
      Component: loadable(() => import('pages/device/edit'), config),
    },
    {
      path: '/appconfig/list',
      Component: loadable(() => import('pages/appconfig/list'), config),
    },
    {
      path: '/appconfig/detail/:id',
      Component: loadable(() => import('pages/appconfig/detail'), config),
    },
    {
      path: '/appconfig/edit/:id',
      Component: loadable(() => import('pages/appconfig/edit'), config),
    },
    {
      path: '/appconfig/create/:id',
      Component: loadable(() => import('pages/appconfig/edit'), config),
    },
    {
      path: '/accountinfo/list',
      Component: loadable(() => import('pages/accountinfo/list'), config),
    },
    {
      path: '/accountinfo/detail/:id',
      Component: loadable(() => import('pages/accountinfo/detail'), config),
    },
    {
      path: '/accountinfo/edit/:id',
      Component: loadable(() => import('pages/accountinfo/edit'), config),
    },
    {
      path: '/accountinfo/create',
      Component: loadable(() => import('pages/accountinfo/edit'), config),
    },
    {
      path: '/signature/list',
      Component: loadable(() => import('pages/signature/list'), config),
    },
    {
      path: '/signature/detail/:id',
      Component: loadable(() => import('pages/signature/detail'), config),
    },
    {
      path: '/signature/edit/:id',
      Component: loadable(() => import('pages/signature/edit'), config),
    },
    {
      path: '/signature/create/:id',
      Component: loadable(() => import('pages/signature/edit'), config),
    },
    {
      path: '/push/list',
      Component: loadable(() => import('pages/push/list'), config),
    },
    {
      path: '/push/detail/:id',
      Component: loadable(() => import('pages/push/detail'), config),
    },
    {
      path: '/push/edit/:id',
      Component: loadable(() => import('pages/push/edit'), config),
    },
    {
      path: '/push/create/:id',
      Component: loadable(() => import('pages/push/edit'), config),
    },
    {
      path: '/systemInfo',
      Component: loadable(() => import('pages/systemInfo/detail'), config),
    },
    {
      path: '/test/edit',
      Component: loadable(() => import('pages/test/edit'), config),
    },
    {
      path: '/downloadFile',
      Component: loadable(() => import('popups/download'), config),
    },
    {
      path: '/payment/list',
      Component: loadable(() => import('pages/payment/list'), config),
    },
    {
      path: '/payment/detail/:id',
      Component: loadable(() => import('pages/payment/detail'), config),
    },
    {
      path: '/payment/history/list',
      Component: loadable(() => import('pages/payment/history/list'), config),
    },
    {
      path: '/payment/history/detail/:id',
      Component: loadable(() => import('pages/payment/history/detail'), config),
    },
    {
      path: '/redeem/list',
      Component: loadable(() => import('pages/redeem/list'), config),
    },
    {
      path: '/redeem/detail/:id',
      Component: loadable(() => import('pages/redeem/detail'), config),
    },
    {
      path: '/redeem/edit/:id',
      Component: loadable(() => import('pages/redeem/edit'), config),
    },
    {
      path: '/redeem/create',
      Component: loadable(() => import('pages/redeem/edit'), config),
    },
    {
      path: '/autodiscover/list',
      Component: loadable(() => import('pages/autodiscover/list'), config),
    },
    {
      path: '/autodiscover/detail/:id',
      Component: loadable(() => import('pages/autodiscover/detail'), config),
    },
    {
      path: '/autodiscover/edit/:id',
      Component: loadable(() => import('pages/autodiscover/edit'), config),
    },
    {
      path: '/autodiscover/create',
      Component: loadable(() => import('pages/autodiscover/edit'), config),
    },
    {
      path: '/statistics',
      Component: loadable(() => import('pages/statistics'), config),
    },
    {
      path: '/logFiles',
      Component: loadable(() => import('pages/logFiles/list'), config),
    },
    {
      path: '/enterprise-site/list',
      Component: loadable(() => import('pages/enterprise-site/list'), config),
    },
    {
      path: '/enterprise-site/detail/:id',
      Component: loadable(() => import('pages/enterprise-site/detail'), config),
    },
    {
      path: '/enterprise-site/detail-report/:id',
      Component: loadable(() => import('pages/enterprise-site/detail/report'), config),
    },
    {
      path: '/enterprise-site/detail-health/:id',
      Component: loadable(() => import('pages/enterprise-site/detail/health'), config),
    },
    {
      path: '/enterprise-site/detail-masteraction/:id',
      Component: loadable(() => import('pages/enterprise-site/detail/master-action'), config),
    },
    {
      path: '/enterprise-site/edit/:id',
      Component: loadable(() => import('pages/enterprise-site/edit'), config),
    },
    {
      path: '/enterprise-site/create',
      Component: loadable(() => import('pages/enterprise-site/edit'), config),
    },
    {
      path: '/enterprise-site/workspace/detail/:esnId/:eswnId',
      Component: loadable(() => import('pages/enterprise-site/esWorkspace/detail'), config),
    },
    {
      path: '/enterprise-site/workspace/edit/:esnId/:eswnId',
      Component: loadable(() => import('pages/enterprise-site/esWorkspace/edit'), config),
    },
    {
      path: '/enterprise-site/purchase-history/detail/:gid',
      Component: loadable(
        () => import('pages/enterprise-site/esWorkspace/purchase/detail'),
        config,
      ),
    },
    {
      path: '/enterprise-site/purchase-history/create/:esnId/:eswnId',
      Component: loadable(() => import('pages/enterprise-site/esWorkspace/purchase/edit'), config),
    },
    {
      path: '/enterprise-site/purchase-history/edit/:gid',
      Component: loadable(() => import('pages/enterprise-site/esWorkspace/purchase/edit'), config),
    },
  ]

  return routes
}

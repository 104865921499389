import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import { history } from 'index'

export function timeFormat(time, formatStr) {
  if (time == null || time.length === 0) {
    return '-'
  }

  let utcTime = time
  if (
    time.length != null &&
    // time.indexOf('T') > 0 &&
    time.indexOf('Z') === -1 &&
    time.indexOf('+') === -1
  ) {
    utcTime = `${time}Z`
  }
  return moment(utcTime).format(formatStr)
}

export function timeFormatDefault(time, defaultStr) {
  if (time == null || time.length === 0) {
    return defaultStr || '-'
  }

  let utcTime = time
  if (
    time.length != null &&
    // time.indexOf('T') > 0 &&
    time.indexOf('Z') === -1 &&
    time.indexOf('+') === -1
  ) {
    utcTime = `${time}Z`
  }

  return `${timeFormat(utcTime, 'l')} ${timeFormat(utcTime, 'LT')}`
}

export function timeFormatUTC(time, formatStr) {
  if (time == null || time.length === 0) {
    return '-'
  }

  let utcTime = time
  if (
    time.length != null &&
    // time.indexOf('T') > 0 &&
    time.indexOf('Z') === -1 &&
    time.indexOf('+') === -1
  ) {
    utcTime = `${time}Z`
  }
  return moment(utcTime).utc().format(formatStr)
}

export function timeFormatUTCDefault(time, defaultStr) {
  if (time == null || time.length === 0) {
    return defaultStr || '-'
  }

  let utcTime = time
  // if (time.length != null && time.indexOf('T') > 0 && time.indexOf('Z') === -1) {
  if (time.length != null && time.indexOf('Z') === -1) {
    utcTime = `${time}Z`
  }

  return `${timeFormatUTC(utcTime, 'l')} ${timeFormatUTC(utcTime, 'LT')}`
}

export function emailAddress(emailStr) {
  if (emailStr) {
    const pos = emailStr.indexOf('@')
    if (pos >= 0) {
      return {
        user: emailStr.substring(0, pos),
        host: emailStr.substring(pos + 1),
        address: emailStr,
      }
    }
  }
  return null
}

export function localeDateTime(num) {
  if (num != null) {
    const time = new Date(num)
    return time.toLocaleString()
  }
  return ''
}

export function compareStr(a, b) {
  // if (a > b) {
  //   return 1
  // }
  // if (a < b) {
  //   return -1
  // }
  // return 0
  const aStr = a || ''
  const bStr = b || ''
  return aStr.localeCompare(bStr)
}

export function getSearchParams() {
  const parsed = queryString.parse(window.location.search, { parseNumbers: true })
  return parsed
}

export function makeSearchString(params) {
  if (params != null) {
    const search = queryString.stringify(params)
    return search
  }
  return ''
}

/* inherit - default: true - 이전에 설정된 parameter를 그대로 유지하면서, 새로 지정된 parameter만 변경. */
export function setSearchParams(params, inherit) {
  if (params == null) {
    return
  }

  // let url = window.location.origin + window.location.pathname

  let prevParams = {}
  if (inherit !== false) {
    prevParams = getSearchParams()
  }

  const newParams = {
    ...prevParams,
    ...params,
  }

  let isChanged = false
  Object.entries(newParams).forEach(([key, value]) => {
    if (isChanged === false && prevParams[key] !== params[key]) {
      isChanged = true
    }
    if (value === undefined) {
      delete newParams[key]
    }
  })

  if (isChanged === true) {
    const urlData = {
      pathname: window.location.pathname,
    }

    const search = queryString.stringify(newParams)
    if (search != null && search.length > 0) {
      // url += `?${search}`
      urlData.search = `?${search}`
    }

    urlData.state = {
      replaceUrl: true,
    }

    // history.push(url)
    history.replace(urlData)
  }
}

const paramInfo = {
  paramProps: {},
  lastSearchStr: null,
}
const paramChangeListeners = []

export function parseSearchParam() {
  paramInfo.paramProps = getSearchParams()
}

export function encodeFilterMap(filterMap, vars, filters) {
  let filterStr = ''
  Object.entries(filterMap).forEach(([key, value]) => {
    const pos = key.lastIndexOf('.')
    const filterKey = key.substring(pos + 1)
    setInFilter(vars.filters, filterKey, filters[key])
    if (filters[key] != null) {
      filterStr += `${key}:`
      let isFirst = true
      filters[key].forEach((val) => {
        const idx = value.indexOf(val)
        if (isFirst !== true) {
          filterStr += `,`
        }
        filterStr += `${idx}`
        isFirst = false
      })
      filterStr += `;`
    }
  })

  return filterStr
}

export function decodeFilterMap(nfilterStr) {
  const nfilter = {}
  const entries = nfilterStr.split(';')
  entries.forEach((str) => {
    if (str.length > 0) {
      const entry = str.split(':')
      if (entry.length > 1 && entry[1].length > 0) {
        const idxList = entry[1].split(',')
        const arr = []
        idxList.forEach((idx) => {
          arr.push(idx)
        })
        nfilter[entry[0]] = arr
      }
    }
  })
  return nfilter
}

export function encodeSortMap(sortMap) {
  let sortStr = ''
  Object.entries(sortMap).forEach(([key, value]) => {
    sortStr += `${key}:${value ? '1' : '0'};`
  })

  return sortStr
}

export function decodeSortMap(nsortStr) {
  const nsort = {}
  const entries = nsortStr.split(';')
  entries.forEach((str) => {
    if (str.length > 0) {
      const entry = str.split(':')
      if (entry.length > 1 && entry[1].length > 0) {
        nsort[entry[0]] = entry[1] === '1'
      }
    }
  })

  return nsort
}

export function encodeNSearch(searchFilters) {
  // return JSON.stringify(searchFilters);

  // value, value.value는 null이 될 수 없다. null 인 경우는 itemKey를 통해 전달됨.
  let searchStr = ''
  Object.entries(searchFilters).forEach(([key, value]) => {
    if (typeof value === 'string') {
      searchStr += `${key.length}I0I0I${value.length}I${key}III${value}I`
    } else {
      const val = typeof value.value === 'string' ? value.value : String(value.value)
      const filter = value.filter != null ? value.filter : ''
      searchStr += `${key.length}I${value.pKey.length}I${filter.length}I${val.length}I${key}I${value.pKey}I${filter}I${val}I`
    }
  })

  return searchStr
}

export function decodeNSearch(nsearchStr) {
  // return JSON.parse(nsearchStr)

  const nsearch = {}
  let pos = 0
  let ppos = 0
  const len = []
  const str = []
  let i
  let tmp

  while (nsearchStr.indexOf('I', ppos) > -1) {
    for (i = 0; i < 4; i += 1) {
      pos = nsearchStr.indexOf('I', ppos)
      if (pos < 0) {
        throw new Error('nsearch param error1.')
      }
      tmp = nsearchStr.substring(ppos, pos)
      ppos = pos + 1
      len[i] = parseInt(tmp, 10)
    }

    for (i = 0; i < 4; i += 1) {
      pos = ppos + len[i]
      if (pos > nsearchStr.length) {
        throw new Error('nsearch param error2.')
      }
      str[i] = nsearchStr.substring(ppos, pos)
      ppos = pos + 1
    }

    const [key, pKey, filter, value] = str
    if (pKey.length === 0) {
      nsearch[key] = value
    } else {
      nsearch[key] = {
        pKey,
        filter,
        value,
      }
    }
  }

  return nsearch
}

export function processNParam() {
  const sparams = paramInfo.paramProps

  if (sparams.nfilter != null) {
    const nfilterStr = decodeURIComponent(sparams.nfilter)

    const nfilter = decodeFilterMap(nfilterStr)

    if (Object.keys(nfilter).length > 0) {
      sparams.nfilter = nfilter
    } else {
      delete sparams.nfilter
    }
  }

  if (sparams.nsort != null) {
    const nsortStr = decodeURIComponent(sparams.nsort)

    const nsort = decodeSortMap(nsortStr)

    if (Object.keys(nsort).length > 0) {
      sparams.nsort = nsort
    } else {
      delete sparams.nsort
    }
  }

  if (sparams.nsearch != null) {
    let nsearch = decodeURIComponent(sparams.nsearch)
    nsearch = decodeNSearch(nsearch)

    if (Object.keys(nsearch).length > 0) {
      sparams.nsearch = nsearch
    } else {
      delete sparams.nsearch
    }
  }

  paramChangeListeners.forEach((func) => {
    func(paramInfo.paramProps)
  })
}

export function checkSearchParams() {
  if (paramInfo.lastSearchStr !== window.location.search) {
    paramInfo.lastSearchStr = window.location.search

    parseSearchParam()
    processNParam()
  }
}

export function getSearchParamProps() {
  checkSearchParams()
  return paramInfo.paramProps
}

export function addParamChangeListener(func) {
  if (paramChangeListeners.indexOf(func) < 0) {
    paramChangeListeners.push(func)
  }
}

export function removeParamChangeListener(func) {
  const pos = paramChangeListeners.indexOf(func)
  if (pos >= 0) {
    paramChangeListeners.splice(pos, 1)
  }
}

export function checkPageReplace(location, prevLocation) {
  if (
    location.pathname === prevLocation.pathname &&
    location.search !== prevLocation.search &&
    (location.state == null || location.state.replaceUrl !== true)
  ) {
    return false
  }
  return true
}

export function getTotalPageCnt(total, pageSize) {
  let totalPage = Math.floor(total / pageSize)

  if (total > pageSize * totalPage) {
    totalPage += 1
  }

  return totalPage
}

// babel을 통해 locale 키를 뽑아내기 위해 사용.
export function i18Key(key) {
  return key
}

export function setFilter(filters, key, value, isRemove, preset) {
  if (value != null && isRemove !== true) {
    if (typeof value === 'string') {
      filters[`${key}Contains`] = value
    } else {
      let val = value.value
      if (preset != null && preset[key] != null) {
        const pItem = preset[key].find((item) => item.key === value.pKey)
        val = pItem.value
      }
      if (value.filter != null) {
        filters[value.filter] = val
      } else {
        filters[`${key}Contains`] = val
      }
    }
  } else if (value != null && value.filter != null) {
    delete filters[value.filter]
  } else {
    delete filters[`${key}Contains`]
  }
}

export function setInFilter(filters, key, value) {
  if (value != null && value.length > 0) {
    filters[`${key}In`] = value
  } else {
    delete filters[`${key}In`]
  }
}

export function getTimeStr(t) {
  if (t <= 0) {
    return '00:00'
  }
  const minute = Math.floor(t / 60)
  const second = Math.floor(t % 60)
  const minStr = minute > 9 ? minute : `0${minute}`
  const secStr = second > 9 ? second : `0${second}`
  const str = `${minStr}:${secStr}`
  return str
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        // eslint-disable-next-line prefer-template
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

// Helper function from: http://stackoverflow.com/a/7557433/274826
export function isElementInViewport(el) {
  // special bonus for those using jQuery
  // if (typeof jQuery === "function" && el instanceof jQuery) {
  //   el = el[0];
  // }
  const rect = el.getBoundingClientRect()
  return (
    (rect.top <= 0 && rect.bottom >= 0) ||
    (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
    (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
  )
}

const createAnimationObj = () => {
  const obj = {
    isRunning: true,
    handle: null,
    isAnimation: window.requestAnimationFrame != null,
    cancel: () => {
      obj.isRunning = false
      if (obj.isAnimation) {
        window.cancelAnimationFrame(obj.handle)
      } else {
        window.clearTimeout(obj.handle)
      }
    },
    start: (callback) => {
      if (obj.isRunning === false) {
        return
      }

      if (obj.isAnimation) {
        obj.handle = window.requestAnimationFrame(callback)
      } else {
        // IE Fallback
        obj.handle = window.setTimeout(callback, 1000 / 60)
      }
    },
  }

  return obj
}

export function checkAnimation(owner, selector, visibleClsName) {
  const obj = createAnimationObj()

  const elementsToShow = owner.querySelectorAll(selector)

  function loop() {
    Array.prototype.forEach.call(elementsToShow, (element) => {
      if (isElementInViewport(element)) {
        element.classList.add(visibleClsName)
      } else {
        element.classList.remove(visibleClsName)
      }
    })

    obj.start(loop)
  }

  // Call the loop for the first time
  loop()

  return obj
}

export function getTitleWithCount(listObj, titleStr) {
  const pageInfo = listObj.getPaginationInfo()

  const totalStr = pageInfo.total > 0 ? `(${pageInfo.total})` : ''

  const title = (
    <span>
      {titleStr} {totalStr}
    </span>
  )

  return title
}

export function openURL(url, option) {
  return () => {
    // option - _blank - 원래 window와 같은 크기로 팝업 생성.
    // option - 'width=800,height=600' - 크기 지정.
    if (option == null) {
      window.open(url)
    } else {
      window.open(url, '', option)
    }
  }
}

export function removeTag(txt) {
  const regex = /<(\/)?([a-zA-Z]*)(\\s[a-zA-Z]*=[^>]*)?(\\s)*(\/)?>/g
  const tagRemove = txt.replaceAll(regex, '')
  return tagRemove
}

export function processError(error, cbFunc) {
  let ret = false
  if (error.code != null) {
    cbFunc(error.code, error.message, error)
    ret = true
  } else if (error.errors || error.graphQLErrors) {
    const errors = error.errors || error.graphQLErrors
    errors.some((errItem) => {
      const code = errItem.extensions != null ? errItem.extensions.code : -1
      if (cbFunc(code, errItem.message, errItem) === true) {
        return true
      }
      return false
    })
    ret = true
  }
  return ret
}

const openFileObj = {
  onChange: null,
  onLoadDataURL: null,
  acceptedType: null,
  sizeLimit: null,
  onSizeError: null,
}

export function openFile(onChange, onLoadDataURL, acceptedType, sizeLimit, onSizeError) {
  openFileObj.onChange = onChange
  openFileObj.onLoadDataURL = onLoadDataURL
  openFileObj.acceptedType = acceptedType
  openFileObj.sizeLimit = sizeLimit
  openFileObj.onSizeError = onSizeError

  let input = document.querySelector('#image-picker')
  if (input == null) {
    input = document.createElement('input')
    input.id = 'image-picker'
    input.setAttribute('type', 'file')
    if (acceptedType != null) {
      input.setAttribute('accept', openFileObj.acceptedType)
    }
    document.body.appendChild(input)

    input.addEventListener('change', (evt) => {
      const file = evt.target.files[0]

      // console.log(file);
      if (openFileObj.sizeLimit == null || file.size < openFileObj.sizeLimit) {
        // this.uploadPhoto(file, loadingFunc)
        if (openFileObj.onChange != null) {
          openFileObj.onChange(file)
        }
        if (openFileObj.onLoadDataURL != null) {
          const reader = new FileReader()

          reader.onload = () => {
            const url = reader.result
            openFileObj.onLoadDataURL(url, file)
          }

          reader.readAsDataURL(file)
        }
      } else if (openFileObj.onSizeError != null) {
        openFileObj.onSizeError()
      }
    })
  }
  input.value = ''
  window.prevInput = input
  /*
    Note: In modern browsers input[type="file"] is functional without
    even adding it to the DOM, but that might not be the case in some older
    or quirky browsers like IE, so you might want to add it to the DOM
    just in case, and visually hide it. And do not forget do remove it
    once you do not need it anymore.
  */

  input.click()
}

const utils = {
  timeFormat,
  emailAddress,
  compareStr,
  getSearchParams,
  makeSearchString,
  setSearchParams,
  getTotalPageCnt,
  i18Key,
  getTimeStr,
  openURL,
}

export default utils
